<template>
    <div>
        <hb-data-table-header>
            <template v-slot:description>
                Add / Manage User Roles
            </template>
            <template v-slot:actions>
                <hb-btn small color="secondary" @click="showRoleObejectIDMapping()">Add Role Mapping</hb-btn>
            </template>
        </hb-data-table-header>
        <v-card elevation="1">
            <v-data-table
                :headers="headers"
                :items="roles"
                disable-pagination
                hide-default-footer
                class="hb-data-table hb-data-table-cursor-on"
                @click:row="showRoleObejectIDMapping"
            >
                <template v-slot:item.name="{ item }">{{ item.name }}</template>
                <template v-slot:item.description="{ item }">{{ item.description }}</template>
                <template v-slot:item.actions="{ item }">
                    <hb-menu options align-right>
                        <v-list>
                            <v-list-item @click="showRoleObejectIDMapping(item)">
                                <v-list-item-title>Edit</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="deleteRoleItem(item)">
                                <v-list-item-title>Delete</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </hb-menu>
                </template>
            </v-data-table>
        </v-card>
        <hb-modal :title="title" v-model="addRoles">
            <template v-slot:content>
                <hb-form label="Role" required>
                    <HbSelect
                        :clearable="false"
                        v-model="role.name"
                        :items="filteredRolesList()"
                        item-text="name"
                        item-value="name"
                        v-validate="'required'"
                        data-vv-scope="default"
                        data-vv-name="name"
                        data-vv-as="Role"
                        :error="errors.has('default.name')"
                        placeholder="Select Role"
                />
                </hb-form>
                <hb-form label="ObjectID" required>
                    <HbTextField v-model="role.object_id"></HbTextField>
                </hb-form>
                <hb-form label="Description" required>
                    <HbTextField v-model="role.description"></HbTextField>
                </hb-form>
            </template>
            <template v-slot:right-actions>
                <hb-btn color="primary" @click="saveRole">
                    <span v-if="role.id">Update Roles</span>
                    <span v-else>Save</span>
                </hb-btn>
            </template>
        </hb-modal>
        <hb-modal v-model="showDelete" show-help-link v-if="showDelete" size="medium" title="Delete Roles" confirmation>
        <template v-slot:content>
          <div class="py-4 px-6">
            Are you sure you want to delete the selected Role mapping?
            <br /><br />
            This action cannot be undone.
          </div>
        </template>
        <template v-slot:actions>
            <hb-btn color="destructive" :disabled="isLoading('generate')" @click="deleteConfirm">Delete</hb-btn>
        </template>
      </hb-modal>
    </div>
</template>

<script>
import { mapGetters } from 'vuex/dist/vuex.common.js';
import api from '../../../../assets/api';

export default {
    name: 'RoleManager',
    data() {
        return {
            roles: [],
            addRoles: false,
            showDelete: false,
            headers: [
                { text: "Role Name", value: "name" },
                { text: "Description", value: "description" },
                { text: "", value: "actions", align: "right", sortable: false, width: 10 }
            ],
            title: '',
            role: {
                id: '',
                name: '',
                description: '',
                object_id: ''
            },
            hbRoles: [],
        }
    },
    created() {
        this.fetchRoles();
        this.fetchHBRoles();
    },
    computed: {
        ...mapGetters({
            allPropertiesList: 'propertiesStore/properties',
        }),
    },
    methods: {
        fetchRoles() {
            api.get(this, api.GET_SSOROLES).then(r => {
                console.log("inside thn:", r)
                this.roles = r.groups;
            });
            console.log("Fetched rolesssss : ", this.roles);
        },
        
        fetchHBRoles(){
                api.get(this, api.ROLES).then(r => {
                    this.hbRoles = r.roles;
                });
                console.log("this.roles :::", this.roles );
        },
        filteredRolesList() {
            console.log("this.roles :::", this.hbRoles );
            return this.hbRoles.length ? this.hbRoles.filter(item => item.name?.toLowerCase().split(" ").join("-")) : [];
        },
        showRoleObejectIDMapping(c = {}) {
            // Reset the group object for a new or existing group
            this.role = {
                id: c.id || '',
                name: c.name || '',
                description: c.description || '',
                object_id: c.object_id || ''
            };
            this.addRoles = true;

            // Set the title based on whether we're editing or adding
            this.title = this.role.id ? 'Edit Roles' : 'Add New Role Mapping';

        },
        async saveRole() {

            var data = { role: this.role };
            console.log("Data to save : ", data);
            // Ensure that the selected properties are included in the data
            if (this.role.id) {
                console.log("Existing Role");
                await api.put(this, api.GET_SSOROLES, data);
            } else {
                console.log("New Role");
                await api.post(this, api.GET_SSOROLES, data);
            }
            this.addRoles = false;
            await this.fetchRoles();
        },
        deleteRoleItem(c){
            this.showDelete = true;
            this.selected = c;
        },
        deleteConfirm(){
            api.delete(this, api.GET_SSOROLES, this.selected.id).then(r => {
            this.showDelete = false;
            this.fetchRoles();
         });
       },
    }
}
</script>