<template>
    <div class="settings-lead">
      <SSOConfiguration v-if="!isPropertyLevel && !isRoleLevel"></SSOConfiguration>
      <GroupManager v-if="isPropertyLevel"></GroupManager>
      <RoleManager v-if="isRoleLevel"></RoleManager>
    </div>
</template>

<script type="text/babel">
    import GroupManager from './GroupManager/GroupManager.vue';
    import RoleManager from './GroupManager/RoleManager.vue';
    import SSOConfiguration from './SSOManager/SSOConfiguration.vue';

    export default {
    name: "SSOConfigurationTabView",
    data() {
        return {
        }
    },
    components: {
        SSOConfiguration,
        GroupManager,
        RoleManager
    },
    props: ["level"],
    computed: {
        isPropertyLevel() {
          return this.level === 'Property' ? true : false;
        },
        isRoleLevel() {
          return this.level === 'Role' ? true : false;
        }
    }
    };
</script>
